import React from "react";

import Page from "lib/@cms/components/layout/Page";
import PageHeader from "lib/@cms/components/cms/PageHeader";
import Text from "lib/@cms/components/shared/Text";
import BodyText from "lib/@cms/components/shared/BodyText";
import Divider from "lib/@cms/components/primitive/Divider";

function Discounts() {
  return (
    <Page pathname="discounts">
      {data => {
        return (
          <React.Fragment>
            <PageHeader
              variant={PageHeader.variant.GRADIENTS}
              color={PageHeader.color.PRIMARY}
              data={data.PageHeaderGradients}
            />
            <Divider className="tw-mb-4" />

            <div className="tw-container">
              <Text variant={Text.variant.BODY}>{data.MetaData.description}</Text>
              <Divider className="tw-mt-8 tw-mb-12 tw-border tw-border-grayscale-6" />

              <ListSection featuresSection={data.FeaturesV2} />
            </div>
            <Divider className="tw-my-16 sm:tw-my-24" />
          </React.Fragment>
        );
      }}
    </Page>
  );
}

export default Discounts;

// --- Components ---

function ListSection({ featuresSection }) {
  return featuresSection?.map((item, index) => {
    const transformationData = { ...item, list: item.descriptions };
    return (
      <React.Fragment key={`text_list_${index}`}>
        <BodyText data={transformationData} variant={BodyText.variant.DECORATION_DOTTEN} />
        <Divider className="tw-my-7" />
      </React.Fragment>
    );
  });
}
